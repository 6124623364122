<template>
    <section>
      <b-card
        no-body
        class="mb-0"
      >
  
        <div class="m-2">
  
          <b-row>
  
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>展示</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>条数据</label>
            </b-col>
  
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="搜索"
                  v-b-tooltip.hover.right="`项目ID 项目名称 公司 金额`"
                />
              </div>
            </b-col>
          </b-row>
  
        </div>
  
        <b-table
          ref="refUserListTable"
          class="position-relative text-nowrap"
          :items="filterStatements"
          :per-page="perPage"
          :current-page="currentPage"
          responsive
          :fields="fields"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
  
        <template #cell(project_id)="data">
            <a 
            v-b-tooltip.hover.right="data.item.project_id"
            class="text-primary"
            @click="toProjectPage(data.item.project_id)"
            >
            {{ data.item.project_id.slice(0, 10) + '...' }}
          </a>
        </template>

        <template #cell(statement_name)="data">
          <a 
            v-b-tooltip.hover.right="data.item.statement_name"
            class="text-primary"
            @click="loadFile(data.item.statement_id,data.item.statement_name)"
            :disabled="fileDownloading">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.statement_name.slice(0, 10) + '...' }}
          </a>
        </template>


        <template #cell(sign_status)="data">
          <b-badge v-if="data.item.sign_status" variant="light-success">
            双方已签署
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未经双方签署
          </b-badge>
        </template>

        <template #cell(email_status)="data">
          <b-badge v-if="data.item.email_status" variant="light-success">
            已发送
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未发送
          </b-badge>
        </template>

        <template #cell(payment_status)="data">
          <b-badge v-if="data.item.payment_status" variant="light-success">
            已回款
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未回款
          </b-badge>
        </template>

        <template #cell(invoice_status)="data">
          <b-badge
            v-b-modal.modal-detail
            href="#"
            v-if="data.item.invoice_status == 'approved'" variant="light-success"
            @click="checkInvoiceDetail(data.item.invoice_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            已通过
          </b-badge>

          <b-badge 
            v-b-modal.modal-detail
            href="#"
            v-else-if="data.item.invoice_status == 'pending'" variant="light-primary"
            @click="checkInvoiceDetail(data.item.invoice_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            审批中
          </b-badge>

          <b-badge 
            v-else-if="data.item.invoice_status == 'rejected'" variant="light-danger"
            v-b-modal.modal-detail
            href="#"
            @click="checkInvoiceDetail(data.item.invoice_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            已驳回
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未申请
          </b-badge>
        </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
  
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item v-if="!data.item.payment_status == true"
                @click="markAsPaid(data.item.statement_id, data.item.invoice_launch_id)" >
                <feather-icon icon="CheckCircleIcon"/>
                <span class="align-middle ml-50">标记为已回款</span>
              </b-dropdown-item>

              <b-dropdown-item v-else
                @click="markAsUnpaid(data.item.statement_id)" >
                <feather-icon icon="XCircleIcon"/>
                <span class="align-middle ml-50">标记为未回款</span>
              </b-dropdown-item>


  
            </b-dropdown>
          </template>
  
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
  
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">正在展示第 {{ dataMeta.from }} 到第 {{ dataMeta.to }} 条数据 （共 {{ totalStatements }} 条数据）</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
  
              <b-pagination
                v-model="currentPage"
                :total-rows="totalStatements"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
  
            </b-col>
  
          </b-row>
        </div>
      </b-card>

      <b-modal
      centered
      ok-only
      ok-title="关闭"
      id="modal-detail"
      :title="checkingApproval.time_cn"
      cancel-variant="outline-secondary"
    >
      <b-card-text
        v-for="i in Object.keys(checkingApprovalData)">
        <span class="mr-1">{{ i }}：</span>
        <span >{{ checkingApprovalData[i] }}</span>
      </b-card-text>

      <hr>

      <b-card-text>
        <app-timeline v-if="Object.keys(checkingApproval.approval_data).length">
          <app-timeline-item
          v-for="workflow in checkingApproval.workflow_status"
            :variant="mapTimelineVariant(workflow.id)"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>{{ workflow.name }}</h6>
            <!--  <small class="text-muted">12 min ago</small> -->
            </div>
            <b-badge v-for="member in workflow.members" class="mr-1" :variant="mapMemberVariant(member,workflow.id)">
              {{mapUserName(member)}}
            </b-badge>
            <small>
              <template v-if="mapNote(workflow.id)">
                备注：{{ mapNote(workflow.id) }}
              </template>

              <template v-if="mapNote(workflow.id) && mapDeliveryCode(workflow.id)">
                ；
              </template>

              <template v-if="mapDeliveryCode(workflow.id)">
                快递单号：{{ mapDeliveryCode(workflow.id) }}
              </template>
            </small>
          </app-timeline-item>
        </app-timeline>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-bank-track-number"
      cancel-variant="outline-secondary"
      ok-variant="primary"
      ok-title="确定"
      cancel-title="关闭"
      centered
      title="标记为已回款"
      @ok="markAsPaid"
      @hide="bankTrackNumber = ''"
    >
      <b-form>
        <b-form-group>
          <label for="note">银行流水号:</label>
          <b-form-input
            v-model="bankTrackNumber"
            id="bank-track-number"
            type="text"
            placeholder=""
          />
        </b-form-group>
       
      </b-form>
    </b-modal>
    </section>
  </template>
    
    <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BModal, VBModal,VBToggle,BCardText,BForm,BFormGroup
  } from 'bootstrap-vue'
  
  import vSelect from 'vue-select'

  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  
    export default {
      components: {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,vSelect,BModal,BCardText,AppTimeline,AppTimelineItem,BForm,BFormGroup
      },
      directives:{
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        'b-modal': VBModal,
      },
      data() {
        return {
            users:[],
          statements:[],
          checkingApproval: { //发票审批流详情
                approval_data: {}
            },
            fileDownloading: false,
            markingStatementId: null,
            bankTrackNumber:"",
          searchQuery: "",
          perPageOptions: [10, 25, 50, 100],
          perPage: 10,
          currentPage: 1,
          sortBy: 'id',
          isSortDirDesc: false,
          fields:[
            {"key":"project_id","label":"项目ID"},
            {"key":"project_name","label":"项目名称"},
            {"key":"company_name","label":"公司"},
            {"key":"user_name","label":"上传用户"},
            {"key":"statement_name","label":"结算单文件"},
            {"key":"summary","label":"金额（元）"},
            {"key":"sign_status","label":"结算单签署状态"},
            {"key":"invoice_status","label":"发票状态"},
            {"key":"payment_status","label":"回款状态"},
            //{"key":"payment_bank_track_number","label":"银行流水号"},
            {"key":"actions","label":"操作"}
        ]
        }
      },
    methods:{
        markAsPaid(markingStatementId, invoiceLaunchId){
/*           if(!this.bankTrackNumber){
            this.$toast.error("请填写银行流水号")
            return
          } */
            var data = {
                statementId: markingStatementId,
                paymentStatus: true,
                invoiceLaunchId: invoiceLaunchId,
            }
            this.$axios.post('/collab/pm/edit_statement_payment_status',data).then(resp =>{
                if(resp.data.status=="ok"){
                    this.getStatements()
                }
            })
        },
        markAsUnpaid(statementId){
            var data = {statementId: statementId, paymentStatus: false}
            this.$axios.post('/collab/pm/edit_statement_payment_status',data).then(resp =>{
                if(resp.data.status=="ok"){
                    this.getStatements()
                }
            })
        },
        toProjectPage(projectId){
            this.$router.push({name: 'collab-pm-view', params: { id: projectId }})
        },
        checkInvoiceDetail(invoiceLaunchId){
            this.$axios.get('/collab/approvals/get_launched_approval/' + invoiceLaunchId).then(resp =>{
                if(resp.data.status=="ok"){
                    this.checkingApproval = resp.data.data.launched_detail
                }
            })
        },
        loadFile(statementId,fileName){
          this.fileDownloading = true
          this.$axios.get("/collab/pm/download_statement/" + statementId).then(res=>{
            this.fileDownloading = false

            if (res.data.status == "ok"){
              const url = this.$backend + '/download_by_token/' + res.data.data.token
              const link = document.createElement('a')
              link.setAttribute('href', url)
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              link.remove()
            }
          })
        },
        getUsers(){
            this.$axios.get("/manage/users/get_users").then((res)=>{
                if (res.data.status == "ok"){
                this.users = res.data.data.users;
                }
            })
        },
        mapUserName(id){
          let user = this.users.find(u => u.user_id == id)
          return user ? user.user_name : ""
        },
        mapTimelineVariant(workflowId){
            var workflow = this.checkingApproval.workflow_status.find((workflow) => {
            return workflow.id == workflowId
            })
            if(workflow.status == "pending"){
                var v = "primary"
            }else if(workflow.status == "approved"){
                var v = "success"
            }else if(workflow.status == "rejected"){
                var v = "danger"
            }else if(workflow.status == null){
                var v = null
            }
            return v
        },
        mapMemberVariant(user,workflowId){
            var workflow = this.checkingApproval.workflow_status.find((workflow) => {
                return workflow.id == workflowId
            })
            if(workflow.member_status.approved.includes(user)){
                var v = "light-success"
            }else if(workflow.member_status.rejected.includes(user)){
                var v = "light-danger"
            }else if(workflow.member_status.pending.includes(user)){
                var v = "light-primary"
            }
            return v
            },
        mapNote(workflowId){
            var workflow = this.checkingApproval.workflow_status.find((workflow) => {
                return workflow.id == workflowId
            })
            if(workflow.note){
                return workflow.note
            }else{
                return false
            }
        },
        mapDeliveryCode(workflowId){
            var workflow = this.checkingApproval.workflow_status.find((workflow) => {
                return workflow.id == workflowId
            })
            if(workflow.delivery_code){
                return workflow.delivery_code
            }else{
                return false
            }
        },
        getStatements(){
            this.$axios.get('collab/pm/get_all_statements').then(response => {
                this.statements = response.data.data.statements

                this.statements.forEach(statement => {
                  Object.keys(statement).forEach(key => {
                    if(statement[key] == null) statement[key] = ""
                  })
                })
            })
        }
      },
      computed:{
        checkingApprovalData: function(){
            var data = JSON.parse(JSON.stringify(this.checkingApproval.approval_data))
            Object.keys(data).forEach((control) => {
                if(typeof(data[control]) != "string"){
                if(!Array.isArray(data[control])){
                    data[control] = data[control]["value"]
                }else{
                    var temp = []
                    data[control].forEach((item) => {
                    temp.push(item["value"])
                    })
                    data[control] = temp.join("；")
                }
            }
            })
            return data
        },
        totalStatements(){
          return this.filterStatements.length
        },
        dataMeta(){
          return {
            from: (this.currentPage - 1) * this.perPage + 1,
            to: Math.min(this.currentPage * this.perPage, this.totalStatements),
          }
        },
        filterStatements(){
          return this.statements.filter( s => {
            return (
                  s.project_id.includes(this.searchQuery) ||
                  s.project_name.includes(this.searchQuery) ||
                  s.company_name.includes(this.searchQuery) ||
                  s.summary.includes(this.searchQuery) 
                )   
            })        
        }
      },
      created(){
        this.getStatements()
        this.getUsers()
      }
  
  
    }
    </script>
  